<template>
  <div class="container-fluid">
    <b-overlay :show="loading">
    <validation-observer ref="refFormObserver">
      <validation-provider #default="validationContext" name="Name" rules="required">
        <b-form-group :label="$t('message.Name')" for="name">
          <b-form-input autofocus v-model="form.name"></b-form-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </b-form-group>
      </validation-provider>

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="onClose">
          {{$t('message.Cancel')}}
        </b-button>
      </div>
    </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {showClientSources, updateClientSources, addClientSources} from '@/api/source_client/source_client'

  export default {
    name: 'CountryForm',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        form: {
          id: null,
          name: '',
        },
        disabled: false,
        loading: false,
      }
    },
    methods: {
      onClose() {
        this.$emit('closeModal')
      },
      handleSubmit() {
        const valid = this.$refs.refFormObserver.validate();
        if (valid) {
          this.disabled = true;
          this.loading = true;
          setTimeout(() => {
            this.disabled = false;
            this.loading = false;
            }, 1000)
          if (this.form.id) {
            updateClientSources(this.form).then(res => {
              this.onClose();
            })
          } else {
            addClientSources(this.form).then(res => {
              this.onClose();
            })
          }
        }
      },
      fetchGetBySources() {
        showClientSources(this.id).then(res => {
          this.form = res.data.data;
        })
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchGetBySources()
          }
        }
      }
    }
  }
</script>