import request from '../../libs/axios'

export function getClientSources(params) {
  return request({
    url: '/api/client-sources',
    method: 'get',
    params,
  })
}

export function showClientSources(id) {
  return request({
    url: `/api/client-sources/${id}`,
    method: 'get',
  })
}

export function addClientSources(data) {
  return request({
    url: '/api/client-sources',
    method: 'post',
    data,
  })
}

export function updateClientSources(data) {
  return request({
    url: `/api/client-sources/${data.id}`,
    method: 'put',
    data,
  })
}

export function deleteClientSources(data) {
  return request({
    url: `/api/client-sources/${data.id}`,
    method: 'delete',
  })
}